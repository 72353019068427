<template>
	<div class="WithdrawAccount">
		
		<div class="Top">
			<div class="Left">
				<el-button type="danger" icon="el-icon-plus" @click="$Jump('/my/finance/withdraw_account_info/add')">新增提现账号</el-button>
			</div>
			<div class="Right">
				<span>
					<el-button type="primary"  @click="$router.go(-1)">返回</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One" v-if="WithdrawAccountList.length == 0">
				<li>
					<span>提示</span>
					<em>当前没有提现账号，<i @click="$Jump('/my/finance/withdraw_account_info/add')">点此创建提现账号</i></em>
				</li>
			</div>
			
			<div class="One" v-for="item in WithdrawAccountList" :key="item.Id">
				<li>
					<span>账号类型</span>
					<em>
						{{item.TypeName}}
						<em style="color: #999999;margin-left: 10px;">
						<template v-if="item.Type == 'person'">个人增值税普票</template>
						<template v-else-if="item.Type == 'normal'">单位增值税普票</template>
						<template v-else-if="item.Type == 'special'">单位增值税专票</template>
						</em>
						<label>创建于{{item.CreatedAt}}</label>
						
					</em>
				</li>
				<li>
					<span>账号</span>
					<em>{{item.No}} <i style="margin-left: 10px;color: rgba(0,0,0,0.4);">({{item.Holder}})</i></em>
				</li>
				<li>
					<span>当前状态</span>
					<em>
						{{item.StatusName}} 
						<template v-if="item.Status == 50">
						- {{item.IsDefaultName}}
						</template>
					</em>
					<i>
						<el-button v-if="item.Status == 50" size="mini" @click="Del(item.Id,10)" type="danger">删除</el-button>
					</i>
				</li>	
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'WithdrawAccountList',
	  props: {
	  },
	  data() {
	      return {
			  WithdrawAccountList:[],
			  Page:1,
			  PageSize:8,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
		this.GetCount()
	  },
	  methods:{
		  handleCurrentChange(val){
		  			 this.GetList(val)
		  },
		  Del(_id){
			  this.$confirm('此操作将删除, 是否继续?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			    }).then(() => {
					this.UpdateWithdrawAccount(_id,10)
			    })
		  },
		  GetList(_page){

		  		let data = {
		  			Service:'Finance',
		  			Class: 'FinanceWithdrawAccount',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					
					if(res.Data.FinanceWithdrawAccountList == null || res.Data.FinanceWithdrawAccountList == undefined){
						this.WithdrawAccountList = []
						this.Page = _page
						this.Total = 0
						return
					}
					
					this.WithdrawAccountList = res.Data.FinanceWithdrawAccountList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  
		  		let data = {
		  			Service:'Finance',
		  			Class: 'FinanceWithdrawAccount',
		  			Action: 'Count',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  					
		  			this.Total = res.Data.Count
		  			
					
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  UpdateWithdrawAccount(_id,_status){
		  	
		  	let data = {
		  		Service:'Finance',
		  		Class: 'FinanceWithdrawAccount',
		  		Action: 'Update',
		  		Id:_id,
		  		Status:_status,
		  	}
		  	
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		
				if(_status == 10){
					this.$message('删除成功')
				}
				
		  		this.GetList()
		  		
		  	})
		  	
		  }
	  }
	}
</script>

<style scoped>
.WithdrawAccount{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.WithdrawAccount .Top{
	display: flex;
	align-items: center;
}
.WithdrawAccount .Top .Left{
	
}
.WithdrawAccount .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.WithdrawAccount .List{
	margin-top: 20px;	
}
.WithdrawAccount .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.WithdrawAccount .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.WithdrawAccount .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.WithdrawAccount .List .One li span{
	width: 100px;
	color: rgba(0,0,0,0.3);
}
.WithdrawAccount .List .One li  em{
	flex: 1;
}
.WithdrawAccount .List .One li  em i{
	color: rgba(228,0,0,1);
	cursor: pointer;
	font-style: normal;
}
.WithdrawAccount .List .One li  em label{
	font-size: 12px;
	color: #999999;
	float: right;
}
.WithdrawAccount .List .One li i{
	
}
</style>
